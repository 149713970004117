import styled from 'styled-components'

export const FormContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  form {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: 1000px) {
      width: 90%;
    }

    .form-group {
      display: flex;
      flex-direction: column;

      label {
        color: #666666;
        font-size: 18px;

        font-family: 'Poppins';
      }

      textarea {
        background-color: #f9f9f9;
        border: none;
        border-radius: 10px;
        padding: 10px 20px;
        height: 100px;
        font-family: 'Poppins';
      }
    }

    .form-control {
      padding: 10px 20px;
      background-color: #f9f9f9;
      border: none;
      border-radius: 10px;
      font-family: 'Poppins';

      &:focus {
        outline: none;
      }
    }

    .col-12 {
      width: 100%;
      display: flex;

      button {
        cursor: pointer;
        background-color: #474344;
        color: #fff;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin: auto;
        padding: 12px 40px 10px;
        height: 50px;
        text-transform: uppercase;
        border: none;
        border-radius: 10px;
      }
    }
  }
`
